<template>
  <div id="sipClient">

    <div class="grid">
      <SipStatusDisplay
        :registerStatus="registerStatus"
        :selectedSite="site"
        :connectStatus="connectStatus"
      />

      <SipControlBar />
    </div>

    <div id="sipChatWrapper">
      <div class="expand">
        <StatusMessage 
          v-if="appStatus !== 1"
        />

        <div 
          v-if="site && site === 'k'"
        >
          <AppCollapsable
            label="Anmeldung im Forsa-CATI-System"
            maxHeight="600px"
            :expanded="true"
          >
            <Shell />
          </AppCollapsable>

          <AppCollapsable
            label="Interview-Datenerfassung im KeyIngress-System"
            maxHeight="800px"
          >
            <KeyIngressIframe />
          </AppCollapsable>
        </div>

        <div
          v-if="site && site !== 'k'"
        >
          <Shell />
        </div>

        <audio id="remoteAudio" controls style="display: none" />
      </div>
    </div>

  </div>
</template>

<script>
import SipStatusDisplay from "./SipStatusDisplay";
import SipControlBar from "./SipControlBar";
import Shell from "./Shell";
import KeyIngressIframe from "./KeyIngressIframe";
import sipMixin from "@/components/mixins/sipLibrary";

export default {
  name: "TheSipClient",

  components: {
    SipStatusDisplay,
    SipControlBar,
    Shell,
    KeyIngressIframe,
  },

  mixins: [sipMixin],

  computed: {
    site() {
      return this.$store.getters.getSite;
    },

    registerStatus() {
      return this.$store.getters.getRegisterStatus;
    },

    connectStatus() {
      return this.$store.getters.getConnectStatus;
    },
  },

  mounted() {
    if (!this.site)
      this.$eventHub.$emit("showSiteSelection");
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto calc(50% + 54px);
  grid-column-gap: 6px;
  align-items: center;
  text-align: left;
  margin-bottom: 12px;
}
#sipClient {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#sipClient .expand {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.collapsable >>> label {
  background: #ccc;
  padding: 6px;
  margin-bottom: 0 !important;
}
.collapsable >>> label span {
  opacity: 1 !important;
}
.collapsable + .collapsable {
  margin-top: 24px;
}
</style>
