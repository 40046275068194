<template>
  <Popup
    width="600px"
    height="500px"
    title="System-Analyse"
    @closePopup="$emit('closePopup')"
  >
    <div>
      Die folgende Übersicht zeigt, welche Möglichkeiten der verwendete Computer bietet.
    </div>

    <h3>
      angeschlossene Audio-Geräte
    </h3>

    <div
      v-if="features"
      class="grid"
    >
      <div>
        Mikrofon
      </div>
      <div>
        <i :class="getIcon(features.hasMicrophone)"></i>
      </div>
      <div>
        <span
          v-if="features.hasMicrophone"
        >
          {{ features.audioInputDevices.map(e => e.label).join(', ') }}
        </span>
      </div>

      <div>
        Zugriff auf Mikrofon erlaubt
      </div>
      <div>
        <i :class="getIcon(features.isWebsiteHasMicrophonePermissions)"></i>
      </div>
      <div></div>
    </div>

    <h3>
      verwendeter Browser
    </h3>

    <div
      v-if="features"
      class="grid"
    >
      <div>
        Name / Version
      </div>
      <div>
        <i :class="getIcon(features.browser.isFirefox || features.browser.isChrome)"></i>
      </div>
      <div>
        {{ features.browser.name }} {{ features.browser.version }}
      </div>

      <div>
        Web-RTC-Unterstützung
      </div>
      <div>
        <i :class="getIcon(features.isWebRTCSupported)"></i>
      </div>
      <div></div>

      <div>
        WebSocket-Unterstützung
      </div>
      <div>
        <i :class="getIcon(features.isWebSocketsSupported && !features.isWebSocketsBlocked)"></i>
      </div>
      <div></div>

      <div>
        Zugriff auf Audio-Geräte
      </div>
      <div>
        <i :class="getIcon(features.isGetUserMediaSupported)"></i>
      </div>
      <div></div>
    </div>

  </Popup>
</template>


<script>
var DetectRTC = require('detectrtc');

export default {

  name: 'SystemCheck',

  data(){
    return {
      features: null
    }
  },

  mounted(){

    DetectRTC.load(
      () => {

        this.features = Object.assign({}, DetectRTC);
      }
    );
  },

  methods: {

    getIcon(val){

      if (val)
        return 'fas fa-check-circle fa-2x';

      return 'fas fa-ban fa-2x';
    },
  },
}
</script>


<style scoped>
.content > * {
  margin-bottom: 24px;
}
.grid {
  display: grid;
  grid-template-columns: 50% 36px auto;
  column-gap: 6px;
  row-gap: 3px;
  align-items: center;
}
.fa-check-circle {
  color: #689F38;
}
.fa-ban {
  color: #cc0000;
}
</style>