<template>
  <div
    id="messenger"
    :class="expanded ? 'expanded' : 'collapsed'"
    :style="'max-width: ' + panelWidth + 'px;'"
  >
    <div id="messengerPanel">
      <MessengerContent
        :expanded="expanded"
        @sendMessage="sendMessage"
      />
    </div>
  </div>
</template>

<script>
import messengerMixin from '@/components/mixins/messengerLibrary';

import MessengerContent from './MessengerContent';

var Resizable = require('resizable');

export default {

  name: 'MessengerPanel',

  components: {
    MessengerContent,
  },

  data(){
    return {
      expanded: false,
      panelWidth: 400,
    }
  },

  mixins: [messengerMixin],

  mounted(){

    this.$eventHub.$on('toggleMessengerPanel', this.toggleMessengerPanel);

    this.$eventHub.$on('collapseMessengerPanel', this.collapseMessengerPanel)

    this.makePanelResizable();
  },

  beforeDestroy(){

    this.$eventHub.$off('toggleMessengerPanel', this.toggleMessengerPanel);

    this.$eventHub.$off('collapseMessengerPanel', this.collapseMessengerPanel)
  },

  methods: {

    toggleMessengerPanel(){

      this.expanded = !this.expanded;
    },

    collapseMessengerPanel(){
      
      this.expanded = false;
    },

    makePanelResizable(){

      let resizable = new Resizable(
        document.querySelector('#messengerPanel'),
        {
          handles: 'w',
          threshold: 10,
          css3: false,
        }
      );

      resizable.on('resizeend', () => {

        this.panelWidth = $('#messengerPanel').outerWidth();
      });
    },
  },
}
</script>

<style scoped>
#messenger.collapsed {
  /* this <div> serves as dummy-container beneath the page-content */
  width: 0;
  flex-grow: 0;
}
#messenger.expanded {
  flex-grow: 1;
}
#messenger #messengerPanel {
  /* this <div> is the real messenger panel which is positioned fixed */
  position: absolute;
  right: 0;
  height: 100%;
  max-height: 100%;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.12), 0 0px 4px rgba(0, 0, 0, 0.24);
  background: #efefee;
  transition: width 0.4s ease-out;
  transform: none !important;
}
#messenger #messengerPanel {
  /* this <div> is the real messenger panel which is positioned fixed */
  position: fixed;
  top: 0;
  padding-top: 80px;
  height: 100%;
  max-height: 100%;
  width: 400px;
  min-width: 180px;
  max-width: 600px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.12), 0 0px 4px rgba(0, 0, 0, 0.24);
  background: #efefee;
  transition: right 0.4s ease-out;
  transform: none !important;
}
#messenger.collapsed #messengerPanel {
  right: -600px;
}
#messenger.expanded #messengerPanel {
  right: 0;
}
</style>
