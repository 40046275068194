<template>
  <div id="header">

    <div>
      <Navigation
        v-if="loggedIn"
        style="width: 340px;"
      />

      <img src="@/assets/logo.png" />
    </div>

    <h3
      v-if="loggedIn"
    >
      {{ name }} / System: {{ usedStack }}
    </h3>

    <MessengerToggle 
      v-if="loggedIn"
    />

  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore';

import Navigation from './TheNavigation';
import MessengerToggle from '../messenger/MessengerToggle'

export default {

  name: 'Header',

  components: {
    Navigation,
    MessengerToggle,
  },

  data(){
    return {
      checkSessionInterval: null,
    }
  },

  mixins: [navigationMixin],

  computed: {

    name(){

      let details = this.$store.getters.getDetails;

      return (details.titel || '') + ' ' + details.vorname + ' ' + details.nachname;
    },
    
    usedStack(){

      let details = this.$store.getters.getDetails;

      return details.usedStack;
    }
  },

  watch: {

    loggedIn(newVal){
      
      if (!!newVal)
        this.bindSessionCheck();
      else
        this.unbindSessionCheck();
    },
  },

  methods: {

    bindSessionCheck(){

      this.checkSessionInterval = window.setInterval(
        () => {

          $.ajax({
            method: 'GET',
            url: this.urls.api + 'check-timeout',
            xhrFields: {
              withCredentials: true
            }
          })
          .fail(
            resp => {

              this.setLogin(false);

              this.$router.push({name: 'logout'});

              this.appError('Sie wurden aus dem System abgemeldet.');
            }
          );

        },
        (5 * 1000 * 60) - 5
      );
    },

    unbindSessionCheck(){

      if (this.checkSessionInterval)
        window.clearInterval(this.checkSessionInterval);
    },
  },
}
</script>


<style scoped>
#header {
  position: relative;
  width: 100%;
  background: #efefee;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 800;
  display: flex;
  align-items: center;
  column-gap: 48px;
}
#header img {
  margin: 15px 6px 9px 48px;
  max-height: 32px;
  max-width: calc(100% - 18px);
}
#header h3 {
  flex-grow: 1;
  text-align: center;
  margin-right: 48px;
}
#userInfo {
  position: absolute; 
  right: 80px; 
  font-size: 20px; 
  font-family: 'Georgia', serif; 
  font-weight: 700; 
  color: #666
}
#userInfo span {
  font-size: 13px; 
  display: block; 
  font-weight: 300;
}
</style>