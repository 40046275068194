<template>
  <div class="singleMessage" :class="{ unread: unread, own: own, broadcast: channel == 'broadcast' }">
    <div v-if="own" class="messageData">
      <span class="messagetime">{{ time | formatDate }}</span>
    </div>
    <div v-else class="messageData">
      <div v-if="channel !== 'broadcast' && channel !== ownName">{{ channel }}</div>
      <span>
        <i v-if="channel == 'broadcast'" class="fas fa-rss" title="Broadcast Nachricht"></i>
        <i v-else-if="channel !== 'broadcast' && channel !== ownName" class="fas fa-poll" title="Gruppennachricht"></i>
        <i v-else class="fas fa-user-friends" title="persönliche Nachricht"></i> {{ userName }} - {{ role }} </span>
      <span class="messagetime">{{ time | formatDate }}</span>
    </div>
    <div class="messageContent">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "SingleMessage",
  props: {
    user: String,
    userName: String,
    role: String,
    text: String,
    time: Number,
    channel: String,
    unread: Boolean,
    uid: Number,
    own: Boolean,
  },

  computed: {

    ownName() {
      let details = this.$store.getters.getDetails;
      return details.name;
    },
  },

  created() {
    window.setTimeout(() => {
      this.setMessageRead(this.uid);
    }, 5000);
  },

  mounted() {
    this.scrollToNewMessage();
  },

  methods: {
    setMessageRead(key) {
      this.$emit("update:unread", false);
    },

    scrollToNewMessage() {
      var elements = document.getElementsByClassName('chatHistory');
      elements[0].scrollTop = elements[0].scrollHeight;
    }
  },
};
</script>

<style>
.singleMessage {
  background: #fff;
  margin: 5px 0;
  padding: 10px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  user-select: none;
}

.singleMessage:not(.own) {
  margin-left: 15px;
  border-radius: 8px 0px 8px 8px;
  background: #e2ffc7;
}

.singleMessage.own {
  margin-left: 0;
  margin-right: 15px;
  border-radius: 8px 8px 8px 0;
  background: #fff;
}

.singleMessage.unread {
  background: #fdfdc4;
}

.messageData {
  font-size: 0.85em;
  font-weight: 700;
  padding-bottom: 5px;
}

.messageData::after {
  content: "\00a0";
  float: none;
  clear: both;
}

.messagetime {
  float: right;
  font-weight: 300;
}
</style>