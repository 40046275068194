<template>
  <div>
    <!-- <AppButton
      @click="openMaAccount"
      style="margin: 7px auto; text-align: center;"
    >
      Für die MA-Potenziale-Schulung hier klicken
    </AppButton> -->
    <iframe 
      :src="iFrameUrl"
    />
    <div class="right">
      <AppLink
        @click="logOffFromIngress"
        style="font-size: 12px; display: inline-block; margin-right: 6px;"
      >
        Aus KeyIngress-System abmelden
      </AppLink>
      <InfoButton>
        Benutzen Sie diesen Link zum Schichtende oder falls das KeyIngress-System 
        folgende Fehlermeldung zeigt:
        <br /><br />
        <i>
          Der Interviewer ist bereits angemeldet.<br />
          Ein weiterer Aufruf ist nicht möglich.
        </i>
        <br /><br />
        Anschließend können Sie sich erneut im KeyIngress-System anmelden.
      </InfoButton>
    </div>
  </div>
</template>

<script>
let kiUrl = 'https://d415.keyingress.de/';

export default {

  name: "KeyIngressIframe",

  data(){
    return {
      iFrameUrl: kiUrl,
      loginPage: null,
    };
  },

  mounted(){

    window.addEventListener('beforeunload', e => {

      e.preventDefault();

      return false;
    });
  },

  methods: {

    // openMaAccount(){

    //   window.open('https://d415.keyingress.de', '_blank').focus();
    // },

    logOffFromIngress(){

      this.iFrameUrl = this.iFrameUrl + '?m=90';

      window.setTimeout(
        () => {
          this.iFrameUrl = kiUrl;
        },
        1000
      );
    },
  },
}
</script>

<style scoped>
iframe {
  width: calc(100% - 1px);
  height: 770px;
  border: 1px solid #ccc
}
.right {
  text-align: right;
}
</style>
