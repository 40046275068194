<template>
  <div class="statusContainer">

    <div
      id="siteSelection"
      title="Ausgewähltes CATI-System"
      @click="triggerSiteSelection"
    >
      <span style="text-transform: uppercase;">
        {{ selectedSite ? selectedSite : '-' }}
      </span>
    </div>

    <div
      :class="registerStatusClass"
      title="Verbindung mit CATI-System"
      @click="triggerRegistration"
    >
      <i class="fas fa-plug"></i>
    </div>

    <div
      id="connectStatus"
      :class="connectStatusClass"
      title="Telefonie-Verbindung"
      @click="triggerConnect"
    >
      <i class="fas fa-phone-volume"></i>
    </div>

  </div>
</template>

<script>
import confirmMixin from '@/components/mixins/confirm'

export default {

  name: 'SipStatusDisplay',

  props: {
    registerStatus: String,
    selectedSite: String,
    connectStatus: String,
  },

  mixins: [confirmMixin],

  computed: {

    registerStatusClass(){

      switch (this.registerStatus) {

        case 'Registered':
          return 'green';

        case 'Registering':
          return 'yellow';

        default:
          return 'red';
      }
    },

    connectStatusClass(){

      switch (this.connectStatus) {

        case 'Established':
          return 'green';

        case 'Establishing':
          return 'yellow';

        default:
          return 'red';
      }
    },
  },

  methods: {
    
    triggerRegistration(){

      if (this.registerStatusClass === 'red')
        this.$eventHub.$emit('register');
    },

    triggerSiteSelection(){

      this.$eventHub.$emit('showSiteSelection');
    },

    async triggerConnect(){

      if (this.connectStatusClass !== 'red')
        return;

      if (!this.selectedSite) {

        this.triggerSiteSelection();

        return;
      }


      this.$eventHub.$emit('connect');
    },
  },
}
</script>

<style scoped>
.statusContainer > div {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #ccc;
  width: 48px;
  height: 48px;
  font-size: 30px;
  text-align: center;
  line-height: 48px;
  margin: 0 6px;
}
#siteSelection {
  text-shadow: 0 0 1px #333;
  cursor: pointer;
}
#siteSelection:hover {
  background: #efefee;
}
.red {
  color: #cc0000;
  text-shadow: 0 0 3px #cc0000;
  cursor: pointer;
}
.red:hover {
  background: #efefee;
}
.yellow {
  color: #ffc107;
  text-shadow: 0 0 3px #ffc107;
}
.green {
  color: #8bc34a;
  text-shadow: 0 0 3px #8bc34a;
}
@keyframes glow {
  0% {
    text-shadow: 0 0 2px #8bc34a;
  }
  50% {
    text-shadow: 0 0 12px #8bc34a;
  }
  100% {
    text-shadow: 0 0 2px #8bc34a;
  }
}
#connectStatus.green {
  animation: glow 4s ease infinite;
}
</style>
