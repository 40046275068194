<template>
  <div 
    id="toggleMessenger"
    :class="chatConnected && chatSvListening ? 'chatConnected' : 'disabled'"
    :title="!chatConnected || !chatSvListening ? 'Der Chat ist zur Zeit nicht verfügbar' : ''"
  >
    <AppLink
      :cls="chatConnected && chatSvListening ? 'chatConnected' : 'disabled'"
      :title="chatConnected && chatSvListening ? 'Klicken um den Chat ein- oder auszublenden' : ''"
      @click="$eventHub.$emit('toggleMessengerPanel')"
    >
      <i
        :class="'fas fa-comments ' + (chatConnected && chatSvListening ? 'chatConnected' : 'chatDisconnected')"
      />
      <span
        v-if="unreadMessages.length && unreadMessages.length != 0"
        class="messageIndicator"
      >
        {{ unreadMessages.length }}
      </span>
    </AppLink>
  </div>
</template>

<script>
import messengerMixin from '@/components/mixins/messengerLibrary';

export default {

  name: 'MessengerToggle',

  mixins: [messengerMixin],

  mounted(){

    this.initMessengerConnection();
  },

  beforeDestroy(){

    if (this.connection)
      this.connection.close();
    
    if (this.checkInterval)
      window.clearInterval(this.checkInterval);
  },

  methods: {

    initMessengerConnection(){

      this.connectMessenger();
    },
  },
}
</script>

<style scoped>
#toggleMessenger {
  position: absolute;
  right: 16px;
  top: 0;
  padding: 10px;
  font-size: 32px;
  z-index: 801;
}
#toggleMessenger.chatConnected {
  position: fixed;
}
#toggleMessenger >>> a.disabled {
  pointer-events: none;
  cursor: default !important;
}
#toggleMessenger >>> a.disabled i {
  cursor: default !important;
}
.chatConnected:hover i {
  color: #e63223;
  cursor: pointer;
}
.chatConnected {
  color: #646457;
}
.chatDisconnected {
  color: #d3d3d2;
}
.chatDisconnected + .messageIndicator {
  display: none;
}
.messageIndicator {
  background: #e63223;
  display: block;
  position: absolute;
  color: #fff;
  font-size: 12px;
  padding: 2px;
  font-weight: 700;
  top: 5px;
  right: 5px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  text-align: center;
  line-height: 16px;
  border: 2px solid #fff;
}
</style>
