<template>
  <div id="messengerContent">
    <div
      v-if="expanded"
      class="chatHistory"
    >
      <SingleMessage
        v-for="(data, index) in storeMessages"
        :key="index"
        :user="data.user"
        :userName="data.userName"
        :role="data.role"
        :text="data.text"
        :time="data.timestamp"
        :uid="data.uId"
        :unread.sync="data.unread"
        :own="data.own"
        :channel="data.channel"
      />
    </div>
    <div class="messageBox">
      <AppTextarea
        v-model="chatMessage"
        css="min-height: 66px;"
      >
        <h4>Nachricht schreiben:</h4>
      </AppTextarea>
      <AppLink
        @click="initSendMessage"
        style="margin-top: 25px;"
      >
        <i class="fas fa-arrow-circle-right" />
      </AppLink>
    </div>
  </div>
</template>

<script>
import SingleMessage from "@/components/sections/messenger/SingleMessage.vue";

export default {
  name: "MessengerWindow",

  data() {
    return {
      chatMessage: "",
    };
  },

  components: {
    SingleMessage,
  },

  props: {
    expanded: Boolean,
  },

  computed: {

    storeMessages() {
      return this.$store.getters.allMessages;
    },
  },

  methods: {

    initSendMessage(){

      this. $emit('sendMessage', this.chatMessage);
      this.chatMessage = '';
    },
  },
};
</script>

<style scoped>
#messengerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.chatHistory {
  padding: 10px;
  width: 100%;
  overflow-y: auto;
}
.messageBox {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: auto 16px;
  column-gap: 6px;
  align-items: center;
}
</style>