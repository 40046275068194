export default {

  methods: {

    async confirm(text){

      let confirm = await this.$swal({
        text: text,
        buttons: {
          cancel: 'Abbrechen',
          confirm: 'Weiter'
        },
      })
      .then(
        resp => {

          return resp;
        }
      );

      if (!confirm) return false;

      return true;
    },
  },
}
