<template>
  <div id="login">

    <h1>
      Herzlich willkommen bei cati@home von forsa.
    </h1>

    <div class="center">
      Bitte melden Sie sich mit den Interviewer-Zugangsdaten an, die Sie von Ihren Supervisoren erhalten haben.
    </div>

    <StatusMessage
      v-if="appStatus !== 1"
    />

    <div class="grid">
      <AppInput
        v-model="username"
        placeholder="Interviewer-Name"
        css="padding: 6px;"
        @enterPressed="login"
      >
        <i class="fas fa-user-circle fa-fw"></i>
        Interviewer-Name
      </AppInput>

      <AppInput
        v-model="password"
        type="password"
        placeholder="Interviewer-Nummer"
        css="padding: 6px;"
        @enterPressed="login"
      >
        <i class="fas fa-key fa-fw"></i>
        Interviewer-Nummer
      </AppInput>

      <AppButton
        @click="login"
        cls="material"
        css="min-width: 144px;"
      >
        <StatusIcon
          v-if="appStatus === 1"
        />
        <span
          v-else
        >
          Anmelden
        </span>
      </AppButton>
    </div>
  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

export default {

  name: 'LoginForm',
   
  components: {
  },


  data(){
    return {
      username: null,
      password: null,
    }
  },

  mixins: [navigationMixin],

  
  mounted(){

    this.checkForCredentials();
  },

  methods: {

    checkForCredentials(){
      if(this.$route.query.token && this.$route.query.user ) {
        this.username = this.$route.query.user;
        this.password = atob(this.$route.query.token);
        this.login();
      }

    },

    login(){

      this.appWorking();

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'login',
        data: {
          username: this.username,
          password: this.password,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus();

          this.setLogin(true);

          let details = readJson(resp);

          if (this.$route.params.seat) {

            details.seat = this.$route.params.seat;

            details.room = '860' + this.$route.params.seat;
          }

          this.$store.dispatch('storeDetails', details);
        }
      )
      .fail(
        resp => {

          switch (resp.status) {

            case 302:
              window.location.href = JSON.parse(resp.responseText);
              break;

            case 400:
              this.appError('Bitte geben Sie Interviewer-Namen und Passwort ein!');
              break;

            case 401:
            case 406:
              this.appError('Der Interviewer-Name oder das Passwort ist falsch!');
              break;

            case 426:
              this.appError('Aktuell sind keine freien cati@home-Plätze verfügbar. Bitte wenden Sie sich an Ihre Supervisoren!');
              break;

            case 428:
              this.appError('Sie sind aktuell nicht für die Schicht eingeplant. Bitte wenden Sie sich an Ihre Supervisoren!');
              break;

            default:
              this.appError('Beim Überprüfen der Zugangsdaten ist ein Fehler aufgetreten. Der Dienst ist derzeit nicht erreichbar.');
          }
        }
      );
    },
  },
}
</script>


<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto auto minmax(120px, 20%);
  grid-column-gap: 12px;
  align-items: end;
}
#login {
  margin-top: 36px;
}
#login > * {
  margin-bottom: 24px;
}
#login > h1 {
  text-align: center;
}
</style>