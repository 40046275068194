import navigationMixin from '@/components/mixins/navigationStore';

export default {

  data(){
    return {
      pollInterval: null,
      messages: [],
      sessionData: [],
      activeChannel: "broadcast",
      activeUsers: [{ userName: "broadcast", channel: "broadcast" }],
    }
  },

  computed: {

    chatConnected(){
      return this.$store.getters.getChatState;
    }, 
    
    chatSvListening(){
      return this.$store.getters.getChatAvailability;
    },

    connection(){
      return this.$store.getters.getConnection;
    },

    userDetails(){
      return this.$store.getters.getDetails;
    },

    site(){
      return this.$store.getters.getSite;
    },

    unreadMessages(){
      return this.messages.filter(i => i.unread == true);
    },
  },

  mixins: [navigationMixin],

  watch: {

    chatSvListening(newVal){

      if (newVal === false)
        this.$eventHub.$emit('collapseMessengerPanel');
    },
  },

  methods: {

    // request the websocket-password from the backend
    async getWsPw(){

      return await $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-messenger-password',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          return resp;
        }
      )
      .fail(
        resp => {

          return false;
        }
      );
    },

    // connect the websocket and bind event-handlers
    async connectMessenger(){

      console.warn("Messenger: Establish Websocket-connection");

      let messengerPw = await this.getWsPw();

      if (messengerPw === false)
        return;
      
      let conn = new WebSocket(
        this.urls.messengerWss.replace(
          '://',
          '://cah:' + messengerPw + '@'
        )
      );

      // when websocket is established, send registerUser-message
      conn.onopen = () => {

        console.warn("Messenger: Websocket connected", this.userDetails, this.site);

        this.$store.dispatch("setChatState", true);

        this.messages = [];

        let message = {
          type:           "registerUser",
          username:       this.userDetails.name,
          interviewerid:  this.userDetails.id,
          firstname:      this.userDetails.vorname,
          lastname:       this.userDetails.nachname,
          seat:           this.userDetails.seat,
          timestamp:      Date.now(),
          role:           "interviewer",
          location:       this.site,
        };

        conn.send(JSON.stringify(message));
      };

      // when message is received, react according to message-type
      conn.onmessage = event => {

        console.warn("Messenger: connection receives message", event.data);

        let messageContent = readJson(event.data);

        switch (messageContent.type) {

          case "Meta":
            this.sessionData.push(messageContent);
            break;

          case "Message":
            if (!messageContent.channel)
              messageContent.channel = "broadcast";
            messageContent.unread =
              this.userDetails.name == messageContent.userName ? false : true;
            messageContent.own =
              this.userDetails.name == messageContent.userName ? true : false;
            messageContent.uId = this.messages.length;
            this.messages.push(messageContent);
            this.$store.dispatch("storeMessages", this.messages);
            break;

          case "Users":
            this.$store.dispatch("setChatAvailability", false);
            this.activeUsers.length = 0;
            for (const [key, value] of Object.entries(messageContent.users)) {
              if (value.role == 'supervisor')
                this.$store.dispatch("setChatAvailability", true);
            }
            break;
        }
      }

      // when websocket is closed, hide messenger-panel and try to reconnect in 10s
      conn.onclose = () => {

        console.warn('Messenger: connection closed');

        this.$store.dispatch("setChatState", false);

        this.$eventHub.$emit('collapseMessengerPanel');

        window.setTimeout(
          this.connectMessenger,
          10000
        );
      }

      // on (connection-)errors close websocket
      conn.onerror = err => {

        console.warn('Messenger: connection encountered error', err.message);

        conn.close();
      };

      this.$store.dispatch('storeConnection', conn);
    },

    // send a chat-message
    sendMessage(chatMessage, messageType = "Message") {

      if (!chatMessage.trim().length)
        return;

      console.warn("Messenger: send message", chatMessage);

      let message = {
        type: messageType,
        user: this.userDetails.name,
        userName: this.userDetails.name,
        seat: this.userDetails.seat,
        text: chatMessage,
        timestamp: new Date().getTime(),
        channel: this.userDetails.name,
        role: "interviewer",
      };

      this.connection.send(JSON.stringify(message));
    },
  },
}