<template>
  <div id="navigation">

    <div>
      <AppLink css="display: block;" @click="show = true" class="toggleMenu">
        <i class="fas fa-bars"></i>
      </AppLink>
    </div>


    <transition name="fadeOut">
      <div id="menuLinks" v-if="show" v-click-outside="onClickOutside">

        <div class="closeContainer">
          <AppLink class="toggleMenu" @click="show = false" title="Menü schließen">
            <img src="@/assets/logo.png" />
            ✕
          </AppLink>
        </div>

        <div class="linkContainer">
          <AppLink @click="$eventHub.$emit('showSiteSelection')" @click.native="show = false">
            <i class="fas fa-map-signs fa-fw"></i>
            CATI-System wechseln
          </AppLink>
        </div>

        <div class="linkContainer">
          <AppLink @click="$eventHub.$emit('echoTest')" @click.native="show = false">
            <i class="fas fa-headset fa-fw"></i>
            Headset testen
          </AppLink>
        </div>

        <div class="linkContainer">
          <AppLink @click="$eventHub.$emit('systemCheck')" @click.native="show = false">
            <i class="fas fa-tasks fa-fw"></i>
            System-Analyse
          </AppLink>
        </div>

        <div v-if="!!site && registerStatus === 'Registered'">
          <div v-if="connectStatus === 'Established'" class="linkContainer">
            <AppLink @click="$eventHub.$emit('disconnect')" @click.native="show = false">
              <i class="fas fa-phone-slash fa-fw"></i>
              Telefonverbindung beenden
            </AppLink>
          </div>

          <div v-else class="linkContainer">
            <AppLink @click="$eventHub.$emit('connect')" @click.native="show = false">
              <i class="fas fa-phone fa-fw"></i>
              In Konferenzraum einwählen
            </AppLink>
          </div>
        </div>

        <div class="linkContainer">
          <AppLink @click="logout" @click.native="show = false">
            <span tabindex="-1">
              <i class="fas fa-power-off fa-fw"></i>
              Abmelden
            </span>
          </AppLink>
        </div>
        <div id="versionNumber">
          UI-Version: {{ versionNumber }}
        </div>
      </div>
    </transition>


  </div>
</template>


<script>
import vClickOutside from 'v-click-outside';

export default {

  name: 'Navigation',

  data() {
    return {
      show: false,
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  computed: {

    site() {

      return this.$store.getters.getSite;
    },

    registerStatus() {

      return this.$store.getters.getRegisterStatus;
    },

    connectStatus() {

      return this.$store.getters.getConnectStatus;
    },
  },

  methods: {

    onClickOutside() {

      if (this.show)
        this.show = false;
    },

    logout() {

      this.$eventHub.$emit('disconnect');

      this.$eventHub.$emit('unregister');

      this.$router.push({ name: 'logout' });
    },
  },
}
</script>


<style>
.toggleMenu {
  margin-left: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  box-shadow: none;
  display: inline-block;
  line-height: 56px;
}

.closeContainer .link,
.closeContainer a {
  display: block;
  text-align: right;
}

.closeContainer .link a {
  padding-right: 12px;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
}
</style>


<style scoped>
@keyframes showNavigation {
  1% {
    left: -92%
  }

  10% {
    left: -60%
  }

  25% {
    left: -30%
  }

  40% {
    left: -14%
  }

  60% {
    left: -5%
  }

  80% {
    left: -1%
  }

  to {
    left: 0
  }
}

#navigation {
  width: 300px;
  position: absolute;
  z-index: 950;
}

#menuLinks {
  box-shadow: 0 9px 18px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
  position: absolute;
  width: 100%;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  left: -100%;
  animation: showNavigation 0.3s forwards;
}

#menuLinks .linkContainer i {
  font-size: 21px;
  margin-right: 9px;
  vertical-align: bottom;
}

.closeContainer,
.linkContainer {
  background: #fff;
}

.closeContainer>>>img {
  position: absolute;
  left: 0;
  top: 0;
  margin: 15px 6px 9px 48px;
  height: 32px;
  max-width: calc(100% - 84px);
  outline: none !important;
}

div.linkContainer:not(:last-of-type)::after {
  content: '';
  width: calc(100% - 24px);
  border-bottom: 1px solid #efefee;
  bottom: 0;
  margin-left: 12px;
  display: block;
}

.linkContainer>>>a {
  display: block;
  padding: 12px;
  color: #000;
  text-decoration: none;
}

.linkContainer>>>a:hover {
  color: #e63223;
}

.linkContainer>>>a.router-link-exact-active {
  color: #fff;
  background: #e63223;
}

.linkContainer>>>a.router-link-exact-active:hover,
.linkContainer>>>a.router-link-exact-active:focus span {
  color: #f9ccc8;
}

.fadeOut-leave-active {
  transition: opacity .4s;
}

.fadeOut-leave-to {
  opacity: 0;
}


#versionNumber {
  font-size: 0.8em; 
  padding: 5px 20px;
}
</style>
