<template>
  <div id="shell">

    <iframe
      :src="urls.shell + '?site=' + site + '&client=' + user"
    >
    </iframe>

  </div>
</template>


<script>
export default {

  name: 'Shell',

  computed: {

    user(){

      return this.$store.getters.getDetails.seat;
    },

    site(){

      return this.$store.getters.getSite === 'k' ? 'b' : this.$store.getters.getSite;
    },
  },
}
</script>


<style>
#shell {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>


<style scoped>
iframe {
  width: 100%;
  min-height: 600px;
  height: 100%;
  border: 6px solid #333;
  box-sizing: border-box;
  flex-grow: 1;
}
</style>