import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/fonts/nunito-sans-v2-latin.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

import SingleMessage from '@/components/sections/messenger/SingleMessage.vue'
Vue.component('SingleMessage', SingleMessage);

// import and register jQuery
import $ from 'jquery'
window.$ = $
window.jQuery = $


// import and register sweet-alert
import VueSwal from 'vue-swal'
Vue.use(VueSwal)


// import and register forsa-webapp-control-collection
import {
//  AppAutocompleteInput,
  AppButton,
//  AppCheckbox,
  AppInput, 
  AppLink, 
//  AppPasswordInput,
  AppRadio,
//  AppSelect,
//  AppTextToggle,
  AppTextarea
} from '@forsa/vue-webapp-controls'

//Vue.component('AppAutocompleteInput', AppAutocompleteInput)
Vue.component('AppButton', AppButton)
//Vue.component('AppCheckbox', AppCheckbox)
Vue.component('AppInput', AppInput)
Vue.component('AppLink', AppLink)
//Vue.component('AppPasswordInput', AppPasswordInput)
Vue.component('AppRadio', AppRadio)
//Vue.component('AppSelect', AppSelect)
//Vue.component('AppTextToggle', AppTextToggle)
Vue.component('AppTextarea', AppTextarea)


// import and register forsa-webapp-wrappers
import {
//  Fullscreen,
  InfoButton,
  Popup,
  AppCollapsable,
//  AppTabs,
//  AppTable,
//  AppTableHeaderCell,
//  sortTableMixin
} from '@forsa/vue-webapp-wrappers';

//Vue.component('Fullscreen', Fullscreen);
Vue.component('InfoButton', InfoButton);
Vue.component('Popup', Popup);
Vue.component('AppCollapsable', AppCollapsable);
//Vue.component('AppTabs', AppTabs);
//Vue.component('AppTable', AppTable);
//Vue.component('AppTh', AppTableHeaderCell);
//Vue.mixin(sortTableMixin);


// import and register forsa-app-status-library
import {
  appStatusMixin,
  StatusIcon,
  StatusMessage,
  FullscreenSpinner
} from '@forsa/vue-webapp-status'

Vue.mixin(appStatusMixin)
Vue.component('StatusIcon', StatusIcon)
Vue.component('StatusMessage', StatusMessage)
Vue.component('FullscreenSpinner', FullscreenSpinner)

import versionMixin from '@/components/mixins/versionMixin';
Vue.mixin(versionMixin);

// define global json-parse-wrapper-function
window.readJson = json => {
  try {

    let parsed = JSON.parse(json);
    return parsed;

  } catch(e) {

    console.error('Error parsing JSON: \n\n', e, json);
    this.appError(null, json);
    return false;
  }
}


// register the global event-hub
Vue.prototype.$eventHub = new Vue()

console.log(process.env.NODE_ENV);

// store server-addresses
let l = window.location;

let api = {
  key: 'api',
  value: (
          process.env.NODE_ENV === 'production' ? 
          '' :
          l.protocol + '//' + l.hostname + '/cati-at-home/webapp/'
         ) + 'api/',
}

store.dispatch('storeUrl', api)

let shell = {
  key: 'shell',
  value: (
          process.env.NODE_ENV === 'production' ?
          l.protocol + '//' + l.hostname :
          'https://cati.forsa.de'
         ) + '/terminal/',
}

store.dispatch('storeUrl', shell)

let sipWss = {
  key: 'sipWss',
  value: (
          process.env.NODE_ENV === 'production' ?
          'wss://' + l.hostname :
          'wss://cati.forsa.de'
         ) + '/ws',
}

store.dispatch('storeUrl', sipWss)

let messengerWss = {
  key: 'messengerWss',
  value: (process.env.NODE_ENV === 'production' ?
          'wss://chat-server.forsa.de/ws' : 
          'wss://chat-server-dev.forsa.de/ws'
         ),
}

store.dispatch('storeUrl', messengerWss)

let host = {
  key: 'host',
  value: (process.env.NODE_ENV === 'production' ? 
          l.hostname : 
          'cati.forsa.de'
         ),
}

store.dispatch('storeUrl', host)


// register url-store-mixin globally
import urlStoreMixin from '@/components/mixins/urlStore'
Vue.mixin(urlStoreMixin)

import moment from 'moment';

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment().format('MM/DD/YYYY hh:mm')
  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('H:mm')
  }
});

// instantiate Vue
new Vue({
  router,
  store,
  render: h => h(App)
})
.$mount('#app')
