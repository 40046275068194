export default {

  computed: {

    loggedIn(){

      return this.$store.state.navigationStore.loggedIn;
    },
  },

  methods: {

    setLogin(state){

      this.$store.dispatch('setLoginState', state);
    },
  },
}
