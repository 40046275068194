<template>
  <div class="controlContainer">

    <div>
      <AppButton
        @click="showNumPad = !showNumPad"
        :class="connectStatus === 'Established' ? '' : 'invisible'"
        :title="'Tonwahlmenü ' + (showNumPad ? 'ausblenden' : 'anzeigen')"
      >
        <i class="fas fa-th fa-fw"></i>
      </AppButton>

      <div
        v-if="!!$store.getters.getSite"
      >
        <AppButton
          v-if="connectStatus === 'Established'"
          @click="$eventHub.$emit('disconnect')"
          cls="material"
          title="Telefonverbindung zum Konferenzraum beenden"
        >
          <i class="fas fa-phone-slash fa-fw"></i>
          Telefonverbindung beenden
        </AppButton>

        <AppButton
          v-else-if="connectStatus === 'Establishing'"
          cls="material"
          title="Telefonverbindung zum Konferenzraum wird hergestellt"
          :disabled="true"
        >
          <i class="fas fa-spinner fa-fw fa-pulse"></i>
          Telefonverbindung wird hergestellt
        </AppButton>

        <AppButton
          v-else
          @click="$eventHub.$emit('connect')"
          cls="material"
          title="Telefonverbindung zum Konferenzraum herstellen"
        >
          <i class="fas fa-phone fa-fw"></i>
          in Konferenzraum einwählen
        </AppButton>
      </div>
    </div>

      <!-- v-if="showNumPad" -->
    <div
      v-if="showNumPad && connectStatus === 'Established'"
      class="numPad"
    >
      <AppButton
        v-for="n in numbers"
        :key="'numKey-' + n"
        @click="$eventHub.$emit('sendTone', n)"
      >
        {{ n }}
      </AppButton>
    </div>

  </div>
</template>

<script>
export default {

  name: 'SipControlBar',

  data(){
    return {
      showNumPad: false,
    }
  },

  computed: {

    connectStatus(){

      return this.$store.getters.getConnectStatus;
    },

    numbers(){

      let arr = [...Array(10).keys()];

      arr.push('*');

      arr.push(arr.shift());

      arr.push('#');

      return arr;
    },
  },
}
</script>

<style scoped>
.controlContainer > div:first-of-type {
  display: grid;
  grid-template-columns: 48px auto;
  grid-column-gap: 6px;
  align-items: center;
  text-align: center;
}
.numPad {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  position: absolute;
  left: calc(50% - 150px);
  top: 54px;
  box-shadow: 0 9px 18px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
  background: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  z-index: 1;
}
.numPad >>> button {
  width: 100%;
}
</style>
