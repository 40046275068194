<template>
  <div id="app">
    <Header />

    <div id="window">
      <router-view />
    </div>
  </div>
</template>


<script>
import "what-input";
import navigationMixin from "@/components/mixins/navigationStore";
import Header from "@/components/sections/header/TheHeader";

export default {
  name: "CatiAtHome",

  components: {
    Header,
  },
  mixins: [navigationMixin],
};
</script>


<style>
* {
  margin: 0;
  padding: 0;
  outline: 0;
}
::selection {
  background: #bfdff1;
}
html,
body {
  height: 100%;
  overflow-x: hidden;
}
body {
  font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
#app {
  min-height: 100%; /*height: 100%;*/
  background: #fff;
  display: flex;
  flex-direction: column;
}
#window {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
}
#window > div {
  min-height: 100%;
  flex-grow: 1;
}
a {
  color: inherit;
}
a > * {
  transition: inherit;
  -webkit-transition: none;
}
a:focus > *,
a:hover {
  color: #e63223;
}
a:focus > * > img,
a:focus > img {
  outline: 1px solid #b1b1b1;
}
div,
input,
textarea,
select,
button {
  box-sizing: border-box;
}
h1,
h2,
h3 {
  font-family: Georgia, "Times New Roman", Times, serif;
}
ul {
  list-style-position: inside;
}
.hidden {
  display: none;
}
.invisible {
  visibility: hidden;
}
.relative {
  position: relative;
}
.center {
  text-align: center;
}
.swal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.swal-modal {
  border-radius: 0;
  border-top: 24px solid #efefee;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.swal-button {
  padding: 0 24px;
  border-radius: 2px;
  max-width: 240px;
  border: none;
  outline: none;
  background-color: #f39991;
  color: #000;
  line-height: 2;
  text-align: center;
  text-decoration: none;
  transition: background 0.1s ease-out;
  font-weight: normal;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: "Nunito Sans", sans-serif;
  min-width: 32px;
  cursor: pointer;
  border-radius: 2px;
}
.swal-button--cancel {
  background-color: #efefee;
}
.swal-button:not([disabled]):hover,
.swal-button:not([disabled]):focus,
.swal-button:not([disabled]):active {
  background-color: #e63223;
}
.swal-button--cancel:not([disabled]):focus,
.swal-button--cancel:not([disabled]):hover {
  background-color: #ddd;
}
.swal-button:focus {
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.swal-button:active {
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.24);
}
.swal-title {
  font-size: 20px !important;
}
.swal-content__input:focus,
.swal-content__textarea:focus {
  outline: none;
  border-color: #e63223;
}
.featherlight:last-of-type {
  background: rgba(0, 0, 0, 0.6) !important;
}
@keyframes showContent {
  1% {
    transform: scale(0.5);
  }
  10% {
    transform: scale(0.76);
  }
  25% {
    transform: scale(0.92);
  }
  40% {
    transform: scale(0.98);
  }
  60% {
    transform: scale(1.04);
  }
  to {
    transform: scale(1);
  }
}
.featherlight .featherlight-content {
  max-width: 1000px;
  animation: showContent 0.3s;
}
.featherlight .featherlight-content button {
  font-size: 18px;
  font-weight: bold;
}
.featherlight .featherlight-content button:hover {
  color: #e63223;
}
#app table {
  font-size: 90%;
  border: none;
}
#app table thead th,
#app table tbody td,
#app table tfoot td {
  border: none;
  background-clip: border-box;
}
#app .swiper-button-next,
#app .swiper-button-prev {
  background: none;
  color: #636355;
  opacity: 0.2;
  transition: color 0.1s ease-out, opacity 0.1s ease-out;
}
#app .swiper-button-next:hover,
#app .swiper-button-prev:hover {
  color: #e63223;
  opacity: 1;
}
#app .swiper-button-disabled {
  color: #d8d8d5;
}
#app .swiper-pagination {
  bottom: 0;
}
#app .swiper-pagination-bullet {
  background: #636355;
}
#app .swiper-pagination-bullet-active {
  background: #e63223;
}
#app .spinner {
  width: 15%;
  max-width: 144px;
}
@media only screen and (max-width: 640px) {
  #app .spinner {
    width: 30%;
    max-width: 144px;
  }
}
</style>
