import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const router = new VueRouter({

  mode: 'history',

  scrollBehavior(to, from, savedPosition){

    if (savedPosition) 
      return savedPosition;

    let w = document.getElementById('window');

    if (w !== null)
      w.scrollTop = 0;

    return {
      x: 0,
      y: 0 
    };
  },

  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/index*',
      name: 'index',
      component: Home,
    },
    {
      path: '/seat/:seat',
      name: 'force-seat',
      component: Home,
    },
    {
      path: '/logout',
      name: 'logout',
    },
    // {
    //   // for this route we need a security barrier for not making the route 
    //   // accessible from outside the forsa-network
    //   path: '/msgtest',
    //   name: 'messenger-test',
    //   component: () => import('../views/MessengerTest.vue'),
    // },
    {
      path: '/*',
      name: 'non-existent',
      component: Home,
    },
  ],
})

router.beforeEach(
  (to, from, next) => {

    console.log('redirect to', to.name)

    // if logout is requested, call to server and return to login (no authorization needed)
    if (to.name === 'logout') {

      store.dispatch('setScopeWorking', 'logout');

      $.ajax({
        method: 'GET',
        url: store.getters.urls.api + 'logout',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          store.dispatch('clearScopes');

          store.dispatch('setLoginState', false);

          next({name: 'index'});

          window.setTimeout(
            () => {

              let arg = {
                scope: null,
                status: 3,
                message: 'Sie wurden aus dem System abgemeldet.',
                detail: '',
              }

              store.dispatch('setAppStatus', arg);
            },
            200
          )
        }
      )
      .fail(
        resp => {

          store.dispatch('clearScopes');
        }
      );
    }

    // if page is reloaded, check server for valid PHP-session
    if (to.name !== 'logout') {

      store.dispatch('clearScopes');

      store.dispatch('setScopeWorking', 'check-login');

      $.ajax({
        method: 'POST',
        url: store.getters.urls.api + 'check-login',
        xhrFields: {
          withCredentials: true
        }
      })
      // if active session is running and access is granted
      .done(
        resp => {

          store.dispatch('deleteScope', 'check-login');

          store.dispatch('setLoginState', true);

          let details = readJson(resp);

          store.dispatch('storeDetails', details);
        }
      )
      // if server responds with an error
      .fail(
        resp => {

          store.dispatch('deleteScope', 'check-login');

          store.dispatch('setLoginState', false);

          let arg = {
            scope: null,
            status: 2,
            message: 'Ein Fehler ist aufgetreten.',
            detail: '',
          }

          // show error-message according to HTTP-code
          switch (resp.status) {

            case 0:
              console.log('Oopsy! Something went wrong. Your call was blocked.');
              break;

            case 400:
              console.log('Your request is invalid!');
              break;

            case 401:
              arg.message = 'Um diesen Bereich zu betreten, müssen Sie sich mit Ihren Zugangsdaten anmelden.';
              store.dispatch('setAppStatus', arg);
              break;

            case 412:
              console.log('There is no matching PHP-session running according to the database.');
              break;

            case 404:
              console.log('The backend is lost, please find it first!');
              break;

            default:
              console.log('Oopsy! Something went wrong.');
              break;
          }
        }
      );
    }




    next();
  }
)

export default router