import Vue from 'vue'
import Vuex from 'vuex'

import { appStatusStore } from '@forsa/vue-webapp-status'

// store-section for the request-url
const urlStore = {

  state: {

    urls: {},
  },

  getters: {

    urls(state) {

      return state.urls;
    },
  },

  mutations: {

    STORE_URL(state, payload) {

      state.urls[payload.key] = payload.value;
    },
  },

  actions: {

    storeUrl(context, payload) {

      if ('key' in payload && 'value' in payload)
        context.commit('STORE_URL', payload);
    },
  },
}

// store-section for the currently navigated page
const navigationStore = {

  state: {
    loggedIn: false,
    details: {},
  },

  getters: {

    getDetails(state) {

      return state.details;
    },

    getLoginState(state) {

      return state.loggedIn;
    },
  },

  mutations: {

    SET_LOGIN_STATE(state, payload) {

      state.loggedIn = payload;
    },

    STORE_DETAILS(state, payload) {

      state.details = payload;
    },
  },

  actions: {

    setLoginState(context, payload) {

      context.commit('SET_LOGIN_STATE', payload);
    },

    storeDetails(context, payload) {

      context.commit('STORE_DETAILS', payload);
    },
  },
}

// store-section for the status of the current session
const sipSessionStore = {

  state: {
    site: null,
    registerStatus: null,
    connectStatus: null,
    messengerStatus: null,
  },

  getters: {

    getSite(state) {

      return state.site;
    },

    getRegisterStatus(state) {

      return state.registerStatus;
    },

    getConnectStatus(state) {

      return state.connectStatus;
    },
  },

  mutations: {

    SET_SITE(state, payload) {

      state.site = payload;
    },

    SET_REGISTER_STATUS(state, payload) {

      state.registerStatus = payload;
    },

    SET_CONNECT_STATUS(state, payload) {

      state.connectStatus = payload;
    },
  },

  actions: {

    setSite(context, payload) {

      context.commit('SET_SITE', payload);
    },

    setRegisterStatus(context, payload) {

      context.commit('SET_REGISTER_STATUS', payload);
    },

    setConnectStatus(context, payload) {

      context.commit('SET_CONNECT_STATUS', payload);
    },
  },
}

// store-section for messenger
const messengerStore = {

  state: {
    messages: [
    ],
  },

  getters: {

    allMessages(state) {

      return state.messages;
    },

    channelMessages: (state) => (channel) => {

      return state.messages.filter(message => message.channel == channel);
    },
  },

  mutations: {

    STORE_MESSAGES(state, payload) {

      state.messages = payload;
    },

    SET_MESSAGE_READ(state, payload) {

      state.messages[payload].readed = true;
    },
  },

  actions: {

    storeMessages(context, payload) {

      context.commit('STORE_MESSAGES', payload);
    },

    setMessageRead(context, payload) {

      context.commit('SET_MESSAGE_READ', payload);
    },
  },
}

// store-section for messengerConnection
const messengerConnection = {

  state: {
    connection: null,
    chatConnected: false,
    chatAvailability: false,
  },

  getters: {

    getChatState(state) {

      return state.chatConnected;
    },

    getConnection(state){

      return state.connection;
    },
    
    getChatAvailability(state){

      return state.chatAvailability;
    },
  },

  mutations: {

    SET_CHAT_STATE(state, payload) {

      state.chatConnected = payload;
    },

    STORE_CONNECTION(state, payload){

      state.connection = payload;
    },

    SET_CHAT_AVAILABILITY(state, payload) {

      state.chatAvailability = payload;
    },
  },

  actions: {

    setChatState(context, payload) {

      context.commit('SET_CHAT_STATE', payload);
    },

    storeConnection(context, payload){

      context.commit('STORE_CONNECTION', payload);
    },
    
    setChatAvailability(context, payload){

      context.commit('SET_CHAT_AVAILABILITY', payload);
    },
  },
}

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    navigationStore,
    urlStore,
    appStatusStore,
    sipSessionStore,
    messengerStore,
    messengerConnection,
  },
})

export default store;