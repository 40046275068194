<template>
  <div id="sipApp">
     <div id="changeSystemAlert" v-if="changeSystemAlert">
      Bitte nach dem Interview zum Serverwechsel hier klicken 
      <AppButton
      @click="logout"
    >
      Serverwechsel
    </AppButton>
    </div>
    <SiteSelection
      v-if="showSiteSelection"
      @closePopup="showSiteSelection = false"
    />

    <SystemCheck
      v-if="showSystemCheck"
      @closePopup="showSystemCheck = false"
    />

    <SipClient 
      class="expand"
    />

  </div>
</template>

<script>
import SiteSelection from './sip/SiteSelection';
import SystemCheck from './sip/SystemCheck';
import SipClient from './sip/TheSipClient';

export default {

  name: 'TheApp',

  components: {
    SiteSelection,
    SystemCheck,
    SipClient,
  },

  data(){
    return {
      showSiteSelection: false,
      showSystemCheck: false,
      catiSystem: null,
      changeSystemAlert: false
    }
  },

  computed: {

      userIdLastDigit(){

        let details = this.$store.getters.getDetails;

        return details.id % 10;
  },
      currentHostname(){
        return window.location.hostname
      }
  },

  watch: {

  catiSystem(newVal, oldVal){

      if ((newVal !== this.currentHostname) && oldVal !== null)
        {
          this.changeSystemAlert = true;}
        else
        {
          this.changeSystemAlert = false;}

  }
},

  mounted() {
    this.getCatiSystem();

    this.getCatiSystemInterval = window.setInterval(
      () => this.getCatiSystem(true),
      10000
    );
  },

  created(){

    this.$eventHub.$on('showSiteSelection', this.setShowSiteSelection);

    this.$eventHub.$on('systemCheck', this.setShowSystemCheck);

    this.$eventHub.$on('clearPopups', this.clearPopups);
  },

  beforeDestroy(){

    this.$eventHub.$off('showSiteSelection', this.setShowSiteSelection);

    this.$eventHub.$off('systemCheck', this.setShowSystemCheck);

    this.$eventHub.$emit('disconnect');

    this.$eventHub.$emit('unregister');
  },

  methods: {

    logout(){
      
      this.$eventHub.$emit('disconnect');

      this.$eventHub.$emit('unregister');

      this.$router.push({name: 'logout'});
    },

    getCatiSystem(){

      $.ajax({
        method: 'GET',
        url: this.urls.api + 'get-cati-system',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
          this.catiSystem = resp
          console.log(this.catiSystem)
        }
       
      )
      .fail(
        resp => {

          this.appError(
            'Beim Abruf ist ein Fehler aufgetreten.', 
            resp.responseText
          )
        }
      );
      },

    setShowSiteSelection(){

      this.showSiteSelection = true;
    },

    setShowSystemCheck(){

      this.showSystemCheck = true;
    },

    clearPopups(){

      this.showSystemCheck = false;

      this.showSiteSelection = false;
    },
  },
}
</script>

<style scoped>
#sipApp,
#sipApp > .expand {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
#changeSystemAlert {background: #e63223; text-align: center; color: #fff; padding: 10px;}
</style>
