<template>
  <div id="home">

    <div>
      <HorizontalContainer>

        <TheApp
          v-if="loggedIn"
        />
  
        <LoginForm
          v-else
        />
  
        <FullscreenSpinner />
  
      </HorizontalContainer>
  
      <MessengerPanel 
        v-if="loggedIn"
      />
    </div>

  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

import LoginForm from '@/components/sections/TheLoginForm'
import TheApp from '@/components/sections/TheApp'
import MessengerPanel from '@/components/sections//messenger/MessengerPanel';

export default {

  name: 'Home',

  components: {
    HorizontalContainer,
    LoginForm,
    TheApp,
    MessengerPanel,
  },

  mixins: [navigationMixin],

  
  mounted() {
  this.deleteQueryParams();
},

methods: {
  deleteQueryParams() {
    // Get the current route object
    const currentRoute = this.$route;

    // Check if there are query parameters to remove
    if (Object.keys(currentRoute.query).length > 0) {
      // Construct a new route without query parameters
      const newRoute = {
        path: currentRoute.path, // Keep the same path
        query: {}, // Remove all query parameters
        hash: currentRoute.hash, // Keep the same hash if any
      };

      // Use router.replace() to navigate to the new route without adding to the history
      this.$router.replace(newRoute);
    }
  }
}
}
</script>


<style>
#home {
  display: flex;
  flex-direction: column;
}
#home > div {
  flex-grow: 1;
  display: flex;
}
#home > div > .hContainer {
  padding: 12px 12px 24px 12px;
  display: flex;
  flex-direction: column;
}
</style>
