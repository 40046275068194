<template>
  <Popup
    width="480px"
    height="360px"
    title="System-Auswahl"
    @closePopup="onClosePopup"
  >

    <StatusMessage
      v-if="scopedStatus['site-selection'] > 1"
      scope="site-selection"
    />

    <h3>
      Bitte wählen Sie das CATI-System aus, das Ihnen von der Supervision mitgeteilt wurde.
    </h3>

    <div>
      <AppRadio
        v-model="site"
        value="b"
      >
        CATI-System B
      </AppRadio>
      <AppRadio
        v-model="site"
        value="d"
      >
        CATI-System D
      </AppRadio>
      <AppRadio
        v-model="site"
        value="f"
      >
        CATI-System F
      </AppRadio>
      <AppRadio
        v-model="site"
        value="k"
      >
        KeyIngress-System
      </AppRadio>
    </div>

    <AppButton
      :disabled="!site"
      @click="setSite"
      cls="material"
    >
      Mit CATI-System verbinden
    </AppButton>

  </Popup>
</template>


<script>
import confirmMixin from '@/components/mixins/confirm'

export default {

  name: 'SiteSelection',

  data(){
    return {
      site: this.getSite(),
    }
  },

  mixins: [confirmMixin],

  computed: {

    connectStatus(){

      return this.$store.getters.getConnectStatus;
    },
  },

  mounted(){

    this.clearAppStatus('site-selection');
  },

  methods: {

    getSite(){

      return this.$store.getters.getSite;
    },

    onClosePopup(){

      if (!this.site) {

        this.appError(
          'Bitte wählen Sie ein CATI-System aus!', 
          null, 
          'site-selection'
        );

        return;
      }

      this.$emit('closePopup');
    },

    async setSite(){

      if (this.connectStatus === 'Established' && this.site !== this.$store.getters.getSite) {

        let dlgResp = await(
          this.confirm(
            'Wenn Sie fortfahren, wird die aktuelle Telefonverbindung getrennt. \n\n Möchten Sie fortfahren?'
          )
        );

        if (!dlgResp)
          return false;

        this.$eventHub.$emit('disconnect');
      }

      this.$store.dispatch('setSite', this.site);

      this.$emit('closePopup');
    },
  },
}
</script>


<style scoped>
h3 {
  margin-bottom: 12px;
}
.button {
  text-align: center;
  margin-top: 24px;
}
</style>