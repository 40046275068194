import { UserAgent, Registerer, Inviter } from 'sip.js';

import confirmMixin from '@/components/mixins/confirm'

export default {

  data(){
    return {
      userAgent: null,
      registerer: null,
      conferenceSession: null,
    }
  },

  mixins: [confirmMixin],

  computed: {

    userDetails(){

      return this.$store.getters.getDetails;
    },

    site(){

      return this.$store.getters.getSite;
    },
  },

  mounted(){

    this.createUserAgent();
        
    this.$eventHub.$on('register', this.register);
        
    this.$eventHub.$on('unregister', this.unregister);

    this.$eventHub.$on('connect', this.call);

    this.$eventHub.$on('disconnect', this.hangup);

    this.$eventHub.$on('sendTone', this.sendDTMF);

    this.$eventHub.$on('echoTest', this.startEchoTest);
  },

  beforeDestroy(){
    
    this.$eventHub.$off('register', this.register);
    
    this.$eventHub.$off('unregister', this.unregister);

    this.$eventHub.$off('connect', this.call);

    this.$eventHub.$off('disconnect', this.hangup);

    this.$eventHub.$off('sendTone', this.sendDTMF);

    this.$eventHub.$off('echoTest', this.startEchoTest);
  },

  methods: {

    // request the sip-password from the backend
    async getSipPw(){

      this.appWorking('get-sip-pw');

      return await $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-sip-password',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-sip-pw');

          return resp;
        }
      )
      .fail(
        resp => {

          this.appError(
            'Beim Seitenaufbau ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu, indem Sie die Taste F5 oder die Aktualisieren-Schaltfläche Ihres Browsers betätigen. Gegebenenfalls sind mehrere Versuche nötig und es hilft, einige Sekunden zu warten.'
          );

          this.$eventHub.$emit('clearPopups');

          return false;
        }
      );
    },

     // create the user-agent-object and start registration
  async createUserAgent(){

    // request sip-password
    let sipPw = await this.getSipPw();

    if (!sipPw)
      return;

    // check if seat is present
    let seat = this.userDetails.seat;

    if (!seat)
      return;

    // create user-agent
    console.warn('SIP: Creating user-agent for seat ' + seat);

    let uri = UserAgent.makeURI(
      'sip:' + seat + '@' + this.urls.host
    );

    let transportOptions = {
      server: this.urls.sipWss,
    };

    let userAgentOptions = {
      authorizationUsername: seat,
      authorizationPassword: sipPw,
      uri,
      transportOptions,
      logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
      sessionDescriptionHandlerFactoryOptions: {
        peerConnectionConfiguration: {
          iceServers: [
              {
                urls: 'stun:stun.forsa.de:3478'
              },
              {
                urls: 'stun:stun.forsa.de:443'
              },
              {
                urls: 'stun:stun.l.google.com:19302'
              },
              {
                urls: 'turn:turn.forsa.de:3478',
                username: '4cebfe08e61d3e675ddcccfaefba2459',
                credential: 'def0ce3473ebd734e5c4c3c12d2887a0'
              },
              {
                urls: 'turn:turn.forsa.de:443',
                username: '4cebfe08e61d3e675ddcccfaefba2459',
                credential: 'def0ce3473ebd734e5c4c3c12d2887a0'
              },
              {
                urls: 'turns:turn.forsa.de:443?transport=tcp',
                username: '4cebfe08e61d3e675ddcccfaefba2459',
                credential: 'def0ce3473ebd734e5c4c3c12d2887a0'
              }
          ],
          iceTransportPolicy: 'all',
          rtcpMuxPolicy: 'require'
        },
      },
    };

    this.userAgent = new UserAgent(userAgentOptions);

    // register the user-agent
    this.register();
  },


    // start registration
    register(){

      if (!this.userAgent)
        this.createUserAgent();

      this.appWorking('register');

      console.warn('SIP: Start registration');

      this.registerer = new Registerer(this.userAgent);

      this.bindRegistrationStateChange();

      this.userAgent
        .start()
        .then(
          () => {

            this.registerer.register();

            this.clearAppStatus();
          }
        )
        .catch(
          error => {

            console.error('SIP: Failed to start user-agent', error);

            this.appError(
              'Beim Seitenaufbau ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu, indem Sie die Taste F5 oder die Aktualisieren-Schaltfläche Ihres Browsers betätigen.'
            );

            this.$eventHub.$emit('clearPopups');
          }
        );
    },


    // manage the registration-changes
    bindRegistrationStateChange(){

      this.registerer.stateChange
        .addListener(
          newState => {

            this.$store.dispatch('setRegisterStatus', newState);

            switch (newState) {

              case 'Registered':

                console.warn('SIP: Registration successful');

                break;

              case 'Terminated':

                console.warn('SIP: Registration terminated unexpectedly');

                break;

              case 'Unregistered':

                console.warn('SIP: Registration terminated');

                break;

              default:

                break;
            }
          }
        );
    },


    // end the registration
    unregister(){

      if (this.registerer.state === 'Registered')
        this.registerer.unregister();
    },


    // initiate a call
  call(dial){

    this.appWorking('call');

    if (!dial) {
      if (this.site === 'k')
        dial = this.site + this.userDetails.seat + 'a' + this.userDetails.id + 'a';
      else
        dial = this.site + this.userDetails.room;
    }

    let target = UserAgent.makeURI(
      'sip:' + dial + '@' + this.urls.host
    );

    console.warn('SIP: Calling ' + target);

    let inviterOptions = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: {
            echoCancellation: true,
            noiseSuppression: false,
            autoGainControl: false,
          },
          video: false,
        },
      },
    };

    let inviter = new Inviter(
      this.userAgent, 
      target, 
      inviterOptions
    );

    this.conferenceSession = inviter;

    this.bindSessionStateChange();

    this.conferenceSession
      .invite()
      .then(() => {
        this.clearAppStatus();
      })
      .catch(error => {
        console.error('SIP: Invite failed', error);

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .catch(() => {
            this.appError('Es wurde kein Mikrofon gefunden.');
          });
      });
  },


    // manage the call-status-changes
    bindSessionStateChange(){

      this.conferenceSession.stateChange
        .addListener(
          newState => {

            this.$store.dispatch('setConnectStatus', newState);

            switch (newState) {

              case 'Establishing':

                console.warn('SIP: Conference-call is establishing');

                this.appWorking(
                  'call'
                );

                break;

              case 'Established':

                console.warn('SIP: Conference-call has been established');

                this.appSuccess(
                  'Die Verbindung zum Konferenzraum wurde hergestellt.', 
                  'call'
                );
    
                this.assignAudioStream();

                break;

              case 'Terminated':

                console.warn('SIP: Conference-call has been terminated');

                this.appError(
                  'Die Verbindung zum Konferenzraum wurde abgebrochen. Um sich wieder mit dem Konferenzraum zu verbinden, klicken Sie auf die Schaltfläche "In Konferenzraum einwählen".',
                  null,
                  'call'
                );

                break;

              default:

                break;
            }
          }
        );
    },


    // access the call-audio and hand it over to the audio-player
    assignAudioStream(){

      let session = this.conferenceSession;

      let sdh = session.sessionDescriptionHandler;

      if (!sdh)
        return;

      let stream = sdh.remoteMediaStream;

      let player = document.getElementById('remoteAudio');

      player.autoplay = true;

      player.srcObject = stream;

      player
        .play()
        .catch(
          error => {

            console.error('SIP: Failed to play media', error);

            this.appError(
              'Beim Starten der Audio-Wiedergabe ist ein Fehler aufgetreten. Bitte starten Sie den Audio-Player manuell.',
            );

            $('#remoteAudio')
              .css('display', 'block');
          }
        );
    },


    // Sending a DTMF-tone
    sendDTMF(tone){

      console.warn('SIP: Sending DTMF-tone', tone);

      const dtmf = tone.toString();

      const duration = 400;

      const body = {
        contentDisposition: 'render',
        contentType: 'application/dtmf-relay',
        content: 'Signal=' + dtmf + "\r\nDuration=" + duration
      };

      const requestOptions = { body };

      this.conferenceSession.info({ requestOptions });
    },


    // Terminating the call
    hangup(){

      console.warn('SIP: Hanging up');

      this.$store.dispatch('setConnectStatus', 'Terminated');

      let session = this.conferenceSession;

      if (!session || !session.state)
        return;

      this.clearAppStatus();

      switch (session.state) {

        case 'Initial':
        case 'Establishing':

          session.cancel();

          break;

        case 'Established':

          session.bye();

          break;

        case 'Terminating':
        case 'Terminated':

          session.bye();

          break;
      }
    },


    // start the echo-test
    async startEchoTest(){

      if (this.conferenceSession && this.conferenceSession.state === 'Established') {

        let dlgResp = await(
          this.confirm(
            'Wenn Sie fortfahren, wird die aktuelle Telefonverbindung getrennt und der Echo-Test gestartet. \n\n Möchten Sie fortfahren?'
          )
        );

        if (!dlgResp)
          return false;

        this.hangup();
      }

      this.call('1234');
    },

  },
};
